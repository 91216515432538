<template>
  <div>
    <div>
      <i :class="trigger.icon"></i> {{ trigger.label }}
      <span v-if="eventsLabel" class="keywords-span">( {{ eventsLabel }} )</span>
    </div>

    <b-modal id="fb-modal" v-model="isShowModal" title="Facebook Lead" size="lg" no-close-on-backdrop>
      <div class="row">
        <div v-if="showRefreshData" class="col-md-12 text-right mb-2">
          <b-button size="sm" variant="success" @click="refreshData">
            <b-spinner v-if="refreshing" small></b-spinner>
            <span v-else><i class="uil uil-refresh"></i> Refresh Data</span>
          </b-button>
        </div>
        <div class="col-md-12">
          <div v-if="showOptions" class="form-group">
            <label>Facebook account <span v-b-tooltip.hover class="cursor-pointer"
                title="You can leave this blank to use system default facebook account."><i
                  class="uil uil-question-circle"></i></span></label>
            <multiselect v-model="automation.options.integration_user" :options="options" :multiple="false"
              :allow-empty="false" track-by="id" placeholder="Select Account" label="name" @input="onAccountChange">
            </multiselect>
          </div>
        </div>
        <div class="col-md-12">
          <label>Pages <span v-b-tooltip.hover class="cursor-pointer"
              title="Please select page you want to listen to."><i
                class="uil uil-question-circle"></i></span></label>
          <div class="text-center">
            <b-spinner v-if="loadingPages" small></b-spinner>
            <multiselect v-else v-model="automation.options.page" :options="pages" :multiple="false" :allow-empty="false"
              track-by="id" placeholder="Select Page" label="name"></multiselect>
          </div>
        </div>
        <div v-if="automation.options.page" class="col-md-12 mt-3">
          <label>Forms <span v-b-tooltip.hover class="cursor-pointer"
              title="Please select form you want to listen to."><i
                class="uil uil-question-circle"></i></span></label>
          <div class="text-center">
            <b-spinner v-if="loadingForms" small></b-spinner>
            <multiselect v-else v-model="selectedForm" :options="forms" :multiple="false"  :allow-empty="false"
              track-by="id" placeholder="Select Form" label="name"></multiselect>
          </div>
        </div>
        <div v-if="selectedForm" class="col-md-12 mt-3">
          <label>Field Mapping <br/><small class="text-muted">Map Facebook fields to fields that currently exist in the system.</small></label>
          <div class="d-flex w-100 align-items-center">
            <div class="fb-fiels w-50">
              <label class="text-muted font-weight-bold">Facebook</label>
            </div>
            <div class="app-fiels w-50 ml-2 mr-4">
              <label class="d-block text-muted font-weight-bold">System</label>
            </div>
          </div>
          <div v-for="(item, index) in mapping" :key="index" class="d-flex w-100 align-items-center mb-2">
            <div class="fb-fiels w-50">
              <multiselect v-model="item.field" :options="fields" :multiple="false"  :allow-empty="false"
                track-by="id" placeholder="Select Field" label="name"></multiselect>
            </div>
            <div class="app-fiels w-50 ml-2">
              <multiselect v-model="item.app_field" :options="appFields" :multiple="false"  :allow-empty="false"
                track-by="id" placeholder="Select Field" label="label"></multiselect>
            </div>
            <b-button
              variant="danger"
              size="sm"
              title="Delete"
              class="ml-1"
              @click="removeField(index)"
            >
              <i class="uil uil-trash"></i>
            </b-button>
          </div>
          <div class="d-block text-right mt-2">
            <b-button
              variant="primary"
              size="sm"
              title="New"
              class="ml-1"
              @click="addField"
            >
              <i class="uil uil-plus"></i> Add New
            </b-button>
          </div>
        </div>
        <div class="col-md-12 small-checkbox mt-4">
          <b-form-checkbox v-model="automation.options.email_double_optin" name="email_double_optin">
            Email Double opt-in
            <span v-b-tooltip.hover class="cursor-pointer" title="Requires new subscribers to confirm their email before adding to this list and sending emails." style="vertical-align: middle;">
          <i  class="uil uil-question-circle"></i>
        </span>
          </b-form-checkbox>
        </div>
        <div v-if="automation.options.email_double_optin" class="col-md-12 small-checkbox mt-2">
          <label for="">Redirect URL <span v-b-tooltip.hover class="cursor-pointer" title="User will be redirected to this URL after clicking on confirmation link." style="vertical-align: middle;">
             <i  class="uil uil-question-circle"></i>
          </span></label>
          <TextInput v-model="automation.options.optin_email_redirect_url"  rules="url" name="optin_email_redirect_url" />
        </div>

      </div>


      <template v-slot:modal-footer>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { AppFields } from '@src/constants'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      options: [],
      pages: [],
      forms: [],
      selectedForm: undefined,
      fields: [],
      appFields: AppFields,
      mapping: [],
      loadingPages: false,
      loadingForms: false,
      refreshing: false,
    }
  },

  computed: {
    eventsLabel() {
      if (this.automation.options.page)
        return this.automation.options.page.name
      return ''
    },

    showOptions() {
      return this.options && this.options.length > 1
    },

    showRefreshData() {
      return this.automation.options.integration_user && this.automation.options.integration_user.id
    }
  },

  watch: {
    'automation.options.page': function(newVal, oldVal) {
      if (newVal) {
        this.getForms()
      }
    },
    'selectedForm': function(newVal, oldVal) {
      this.initFbFiels()
    },
  },

  mounted() {
    this.$parent.$on('showModal', this.showModal)
    this.automation.options = {
      ...{ email_double_optin: false, optin_email_redirect_url : null },
      ...this.automation.options
    }
    this.initData()
  },

  methods: {
    initData() {
      if (this.automation.options.page) {
        this.getForms()
      }
      this.initFbFiels()
    },

    refreshData() {
      this.refreshing = true
      this.$store
        .dispatch('integration/syncData', this.automation.options.integration_user.id)
        .then((integration) => {
          this.refreshing = false
          this.initData()
        })
        .catch(() => {
          this.refreshing = false
        })
    },

    save() {
      this.isShowModal = false

      this.automation.options.form = undefined;
      if (this.selectedForm) {
        this.automation.options.form = {
          id: this.selectedForm.id,
          name: this.selectedForm.name
        }
      }
      
      this.automation.options.fb_mapping = this.mapping.filter(item => item.field && item.app_field)
        .map(item => {
          return {
            field_id: item.field.id,
            app_field_id: item.app_field.id
          }
        })
    },

    showModal() {
      this.isShowModal = true
      this.getSelectOptions()
    },

    addField() {
      let lastItem = this.mapping[this.mapping.length - 1]
      if (lastItem && (!lastItem.field || !lastItem.app_field)) {
        Vue.$toast.open({
          message: 'Please select mapping above to add more.',
          type: 'error',
          duration: 5000,
        })
        return
      }
      this.mapping.push({ 
        field_id: undefined, app_field_id: undefined
      })
    },

    removeField(index) {
      this.mapping.splice(index, 1)
    },

    initFbFiels() {
      this.fields = []
      if (this.selectedForm) {
        let form = this.forms.find(item => item.id === this.selectedForm.id)
        if (form) {
          this.fields = form.fields
        } else {
          this.selectedForm = undefined
        }
      } else {
        this.fields = []
      }
      
      this.initMapping()
    },

    initMapping() {
      if (this.automation.options.fb_mapping) {
        this.mapping = this.automation.options.fb_mapping.map(item => {
          return {
            field: this.fields.find(i => i.id === item.field_id),
            app_field: this.appFields.find(i => i.id === item.app_field_id),
          }
        }).filter(item => item.field)
      }
      if (!this.mapping.length) {
        this.addField()
      }
    },

    getSelectOptions() {
      let queries = {
        key: ['fb']
      }
      this.$store.dispatch('integration/selectOptions', queries)
        .then(options => {
          this.options = options
          if (options && options.length === 1) {
            this.automation.options.integration_user = options[0]
          }
          this.getPages()
        })
        .catch(() => { })
    },

    getPages() {
      this.loadingPages = true
      let params = {
        'integration_id': this.automation.options.integration_user ? this.automation.options.integration_user.id : undefined
      }

      this.$store.dispatch('integration/getFbPages', params)
        .then(pages => {
          this.pages = pages
          this.loadingPages = false
        })
        .catch(() => {
          this.loadingPages = false
        })
    },

    getForms() {
      this.loadingForms = true
      let params = {
        'integration_id': this.automation.options.integration_user ? this.automation.options.integration_user.id : undefined,
        'page_id': this.automation.options.page ? this.automation.options.page.id : undefined
      }

      this.$store.dispatch('integration/getFbForms', params)
        .then(forms => {
          this.forms = forms
          this.loadingForms = false
          if (this.automation.options.form) {
            this.selectedForm = this.forms.find(item => item.id === this.automation.options.form.id)
          } else {
            this.selectedForm = undefined
          }
        })
        .catch(() => {
          this.loadingForms = false
        })
    },

    onAccountChange() {
      this.getPages()
    }
  },
}
</script>

<style lang="scss" scoped>
.keywords-span {
  display: block;
  font-size: 13px;
  font-weight: normal;
}
</style>

<style lang="scss">
#fb-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>